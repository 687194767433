import actions from './actions';
import mutations from './mutations';
import state from './state';
import { actionsWrapper, mutationsWrapper } from './wrapper';
import { NAMESPACE } from '@pixcap/ui-core/models/store/readyplayerme.interface'

export { mutations, actions, state, actionsWrapper, mutationsWrapper, NAMESPACE };

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
}