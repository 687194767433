import { IExportState } from '@pixcap/ui-core/models/store/projectexports.interface';
import { SortBy } from '@pixcap/ui-core/models/store/app.interface';

const getDefaultState = function (): IExportState {
	return {
		showExportFBXModal: false,
		showExportImageModal: false,
		exportVideoModal: undefined,
		exportGifModal: undefined,
		showExportProjectModal: null,
		exportedFBXFiles: [],
		isExportingVideo: false,
		exportingVideoProgress: null,
		exportVideoPayload: null,
		exportModalActiveTab: null,
		exportItemsFilter: {
			sortBy: SortBy.RECENT,
			currentPage: 0,
		},
		serverExportItems: [],
		isFetchingExportDownloadItems: false,
		exportDownloadItems: [],
		totalExportDownloadItems: null,
		currentPage: 0,
		totalPages: null,
		selectedServerExportItem: null,
		serverExportItemToCancel: null,
	};
};

export default (): IExportState => getDefaultState();
