import { MutationTypes, IExportState, PixRenderExportStatus, ExportDownloadType } from '@pixcap/ui-core/models/store/projectexports.interface';

export default {
	[MutationTypes.SET_SHOW_EXPORT_MODAL](state: IExportState, showExportFBXModal) {
		state.showExportFBXModal = showExportFBXModal;
	},
	[MutationTypes.SET_SHOW_EXPORT_IMAGE_MODAL](state: IExportState, showExportImageModal) {
		state.showExportImageModal = showExportImageModal;
	},
	[MutationTypes.SET_EXPORT_VIDEO_MODAL](state: IExportState, exportVideoModal) {
		state.exportVideoModal = exportVideoModal;
	},
	[MutationTypes.SET_EXPORT_GIF_MODAL](state: IExportState, exportGifModal) {
		state.exportGifModal = exportGifModal;
	},
	[MutationTypes.SET_SHOW_EXPORT_PROJECT_MODAL](state: IExportState, showExportProjectModal) {
		state.showExportProjectModal = showExportProjectModal;
	},
	[MutationTypes.SET_EXPORTED_FBX_FILES](state: IExportState, fbxFiles) {
		if (Array.isArray(fbxFiles)) {
			state.exportedFBXFiles = fbxFiles;
		} else {
			const exportedFBXFiles = [...state.exportedFBXFiles];
			exportedFBXFiles.unshift(fbxFiles);
			state.exportedFBXFiles = exportedFBXFiles;
		}
	},
	[MutationTypes.UPDATE_EXPORT_FBX_FILE](state: IExportState, fbxFile) {
		const exportedFBXFiles = [...state.exportedFBXFiles];
		const fileIndex = exportedFBXFiles.findIndex((item) => item.exportId == fbxFile.exportId);
		if (fileIndex >= 0) exportedFBXFiles[fileIndex] = fbxFile;
		state.exportedFBXFiles = exportedFBXFiles;
	},
	[MutationTypes.DELETE_FBX_EXPORT_FILE](state: IExportState, exportId) {
		const exportedFBXFiles = state.exportedFBXFiles;
		state.exportedFBXFiles = exportedFBXFiles.filter((file) => file.exportId !== exportId);
	},
	[MutationTypes.SET_IS_EXPORTING_VIDEO](state: IExportState, isExportingVideo) {
		state.isExportingVideo = isExportingVideo;
	},
	[MutationTypes.UPDATE_EXPORTING_VIDEO_STATUS](state: IExportState, exportingVideoProgress) {
		state.exportingVideoProgress = exportingVideoProgress;
	},
	[MutationTypes.SET_VIDEO_EXPORT_PAYLOAD](state: IExportState, exportVideoPayload) {
		state.exportVideoPayload = exportVideoPayload;
	},
	[MutationTypes.SET_EXPORT_MODAL_ACTIVE_TAB](state: IExportState, exportModalActiveTab) {
		state.exportModalActiveTab = exportModalActiveTab;
	},
	[MutationTypes.SET_EXPORT_ITEMS_FILTER](state: IExportState, exportItemsFilter) {
		state.exportItemsFilter = {
			...state.exportItemsFilter,
			...exportItemsFilter,
		};
	},
	[MutationTypes.ADD_OR_UPDATE_SERVER_EXPORT_ITEM](state: IExportState, payload) {
		const serverExportItems = [...state.serverExportItems];
		const itemIndex = serverExportItems.findIndex((item) => item.renderId == payload.renderId);
		if (itemIndex >= 0 && payload.status == PixRenderExportStatus.REMOVED) {
			serverExportItems.splice(itemIndex, 1);
		} else if (itemIndex >= 0) {
			serverExportItems[itemIndex] = { ...serverExportItems[itemIndex], ...payload };
		} else {
			serverExportItems.unshift({ ...payload });
		}
		state.serverExportItems = serverExportItems;
	},
	[MutationTypes.REMOVE_SERVER_EXPORT_ITEM](state: IExportState, renderId) {
		const itemIndex = state.serverExportItems.findIndex((item) => item.renderId == renderId);
		if (itemIndex >= 0) {
			const serverExportItems = [...state.serverExportItems];
			serverExportItems.splice(itemIndex, 1);
			state.serverExportItems = serverExportItems;
		}
	},
	[MutationTypes.RESET_SERVER_EXPORT_ITEMS](state: IExportState) {
		state.serverExportItems = [];
	},
	[MutationTypes.SET_IS_FETCHING_EXPORT_DOWNLOADS](state: IExportState, isFetchingExportDownloadItems) {
		state.isFetchingExportDownloadItems = isFetchingExportDownloadItems;
	},
	[MutationTypes.SET_EXPORT_DOWNLOADS](state: IExportState, payload) {
		const { items, totalItems, totalPages, currentPage, refresh } = payload;
		state.exportDownloadItems = !refresh ? [...state.exportDownloadItems, ...items] : items;
		state.totalExportDownloadItems = totalItems;
		state.currentPage = currentPage;
		state.totalPages = totalPages;
	},
	[MutationTypes.ADD_OR_UPDATE_EXPORT_DOWNLOAD_ITEM](state: IExportState, payload) {
		const exportDownloadItems = [...state.exportDownloadItems];
		const itemIndex = state.exportDownloadItems.findIndex((item) => item.renderId == payload.renderId);
		let match = true;
		if (state.exportItemsFilter.currentPage) {
			match = false;
		} else if (state.exportItemsFilter.exportStatus && payload.status !== state.exportItemsFilter.exportStatus) {
			match = false;
		} else if (state.exportItemsFilter.exportDownloadType == ExportDownloadType.AI_GENERATED) {
			match = false;
		} else if (
			state.exportItemsFilter.search &&
			!(payload.fileName && payload.fileName.toLowerCase().includes(state.exportItemsFilter.search.toLowerCase()))
		) {
			match = false;
		}
		match = match && payload.status !== PixRenderExportStatus.REMOVED;
		if (itemIndex >= 0 && !match) {
			exportDownloadItems.splice(itemIndex, 1);
			state.totalExportDownloadItems -= 1;
		} else if (itemIndex >= 0 && match) {
			exportDownloadItems[itemIndex] = { ...exportDownloadItems[itemIndex], ...payload };
		} else if (match) {
			exportDownloadItems.unshift(payload);
			state.totalExportDownloadItems += 1;
		}

		state.exportDownloadItems = exportDownloadItems;
	},
	[MutationTypes.REMOVE_EXPORT_DOWNLOAD_ITEM](state: IExportState, renderId) {
		const itemIndex = state.exportDownloadItems.findIndex((item) => item.renderId == renderId);
		if (itemIndex >= 0) {
			const exportDownloadItems = [...state.exportDownloadItems];
			exportDownloadItems.splice(itemIndex, 1);
			state.exportDownloadItems = exportDownloadItems;
			state.totalExportDownloadItems--;
		}
	},
	[MutationTypes.SET_SELECTED_SERVER_EXPORT_ITEM](state: IExportState, selectedServerExportItem: string) {
		state.selectedServerExportItem = selectedServerExportItem;
	},
	[MutationTypes.SET_SERVER_EXPORT_ITEM_TO_CANCEL](state: IExportState, serverExportItemToCancel: string) {
		state.serverExportItemToCancel = serverExportItemToCancel;
	},
	[MutationTypes.RESET_EXPORT_DOWNLOADS](state: IExportState) {
		state.exportDownloadItems = [];
		state.totalExportDownloadItems = null;
		state.currentPage = 0;
		state.totalPages = null;
	},
};
