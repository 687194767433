import { Commit, Dispatch } from "vuex";
import { NAMESPACE, MutationTypes, ActionTypes } from '@pixcap/ui-core/models/store/readyplayerme.interface'

export const mutationsWrapper = {
    setShowReadyPlayerMeModal({ commit }: { commit: Commit }, showReadyPlayerMeModal: boolean) {
        commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_READY_PLAYER_ME_MODAL}`, showReadyPlayerMeModal, { root: true });
    },
}

export const actionsWrapper = {
    saveRpmAvatar({ dispatch }: { dispatch: Dispatch }, avatarId: string, url: string) {
        return dispatch(`${NAMESPACE}/${ActionTypes.SAVE_RPM_AVATAR}`, { avatarId, url }, { root: true });
    },
}