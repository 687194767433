import { GetterTypes, PixRenderExportStatus } from '@pixcap/ui-core/models/store/projectexports.interface';
import { MAX_PIX_RENDER_JOBS } from '@pixcap/ui-core/constants/projectexports.constants';

export default {
	[GetterTypes.IS_SERVER_EXPORT_LIMIT_EXCEEDED]: (state) => {
		const serverExportItems = state.serverExportItems;
		let runningRequests = 0;
		for (const exportItem of serverExportItems) {
			if (exportItem.status === PixRenderExportStatus.IN_PROGRESS || exportItem.status === PixRenderExportStatus.PREPARING) runningRequests += 1;
			if (runningRequests >= MAX_PIX_RENDER_JOBS) return true;
		}
		return false;
	},
};
