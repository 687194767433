import type { ActionContext } from 'vuex';
import logger from '@pixcap/ui-core/helpers/logger';
import { HttpClient } from '@pixcap/ui-core/services/httpclient/HttpClient';
import { API_PATHS } from '@pixcap/ui-core/constants/api.constants';
import { ActionTypes } from '@pixcap/ui-core/models/store/readyplayerme.interface';

const ASSETMANAGER_PATH = API_PATHS.ASSETMANAGER_PATH;

export default {
	async [ActionTypes.SAVE_RPM_AVATAR](context: ActionContext<any, any>, payload: { avatarId: string; url: string }) {
		try {
			const { avatarId, url } = payload;
			const response = await (this.$httpClient as HttpClient).post<{ fileId: string }>(`${ASSETMANAGER_PATH}/character/rpm`, { avatarId, url });
			const content = response.data;
			const { fileId } = content;
			return fileId;
		} catch (error) {
			logger.error({ error }, 'Encounter error saving RPM avatar');
		}
		return null;
	},
};
